import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class MYOB extends React.Component {
	render() {
		const siteTitle = 'MYOB'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>MYOB</h3>
								<p>
									<img
										src="https://d33wubrfki0l68.cloudfront.net/img/wsc/7d2ff994372059256ded2a253f7256956e83b6c4/myob.jpg"
										class="img-responsive"
										alt="MYOB"
									/>
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default MYOB

export const MYOBPageQuery = graphql`
	query MYOBPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
